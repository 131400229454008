<template>
  <div class="resetPassword xi">
    <nh-com></nh-com>
    <!-- <div class="banner">
            <div class="banner-wrap clearfix">
                <div class="banner-text">
                    <h3>个人中心</h3>
                    <p>当前位置：
                        <router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a>个人中心</a>
                    </p>
                </div>
            </div>
        </div> -->
    <div class="startBtnBox">
      <div class="startBtnBoxFlex">
        <div class="startBtnBoxImg">
          <img src="@/assets/img/banimg.png" mode="" />
        </div>
        <div class="startBtnBoxRight">
          <div class="" style="font-size: 24px; text-align: center; font-weight: bold">中医辨病论治</div>
          <div class="" style="margin-top: 20px">辨病论治是中医诊疗疾病的一种基本方法，即根据不同疾病的各自特征，作出相应的疾病诊断，并针对不同疾病，进行相应的或特异的治疗。</div>
          <div class="" style="margin-top: 20px">一种具体的病往往具有特定的病因、病机和症状，因而显示其特异性，并反映在病因作用和正虚邪凑的条件下，体内出现一定发展规律的邪正交争、阴阳失调的全部演变过程。因此，辨病论治可以把握疾病的基本矛盾变化，有利于从疾病的全局考虑其治疗方法，而且还能采用某些特异性治法和方药，进行特异性治疗。</div>
          <div class="" style="margin-top: 20px">问诊结果仅供参考，并不构成专业的医疗建议。如有需求，最好咨询医生或有资质的医疗机构。</div>

          <div class="startBtn" @click="goStart">开始问诊</div>
        </div>
      </div>
    </div>
    <ft-com></ft-com>
    <UpgradeMsgBox ref="upgradeMsgBox" @currentlyAvailable="getCurrentlyAvailable" :fullPath="$router.currentRoute.fullPath"></UpgradeMsgBox>
  </div>
</template>

<script>
import ftCom from "../../components/footer";
import nhCom from "../../components/navheader";

export default {
  name: "resetPassword",
  components: {
    ftCom,
    nhCom,
  },
  data() {
    return {
      labelPosition: "right",
      resetPassword: {
        oldPwd: "",
        newPwd: "",
        confirmPwd: "",
      },
      uploader: null,
      file: "",
      patientType: 1, // 固定值
      organitionId: 0, // 固定值
      keyWord: "",
      pageIndex: 1,
      userInfo: {},
      patientList: [],
      visible: false,
      currentlyAvailable: false,
    };
  },
  methods: {
    getCurrentlyAvailable(data) {
      this.currentlyAvailable = data;
    },
    goStart() {
      if (!this.currentlyAvailable) {
        this.$refs.upgradeMsgBox.openTip();
        return;
      }
      this.$router.push("./diseaseStart");
    },
    getList() {
      var _this = this;
      _this.userInfo = this.$store.state.localData.userInfo;
      console.log(_this.keyWord);
      _this.patientDomain.getPatientList(
        this.patientType,
        _this.userInfo.organizationId,
        _this.userInfo.id,
        "",
        "",
        _this.keyWord,
        _this.pageIndex,
        function (data) {
          _this.patientList = data.data.results;
          for (var i = 0; i < _this.patientList.length; i++) {
            var f = _this.patientList[i].addTime.split("T");
            _this.patientList[i].addTime = f[0] + " " + f[1].slice(0, 8);
          }
        },
        function (error) {
          console.log(error);
        }
      );
    },
  },
};
</script>

<style scoped>
@import "../../assets/css/footer.css";

.startBtnBox {
  width: 60%;
  background: #ffb96f;
  border-radius: 19px;
  padding: 40px 30px 42px 32px;
  margin-top: 60px;
  margin-left: 20%;
  margin-bottom: 60px;
}
.startBtn {
  margin-top: 30px;
  background: #f5a653;
  border-radius: 30px;
  color: #ffffff;
  padding: 10px 0;
  text-align: center;
  position: absolute;
  bottom: 20px;
  width: 90%;
  cursor: pointer;
}
.startBtnBoxImg {
  padding-left: 60px;
  margin-top: 50px;
  width: 380px;
  height: 400px;
}
.startBtnBoxImg img {
  width: 100%;
  height: 100%;
}
.startBtnBoxFlex {
  display: flex;
  /* justify-content: space-between; */
}
.startBtnBoxRight {
  width: calc(80% - 380px);
  background: #ffffff;
  border-radius: 48px;
  padding: 40px;
  position: relative;
  margin-left: 50px;
}
</style>
<style>
.personal-center {
  display: none;
}
</style>
